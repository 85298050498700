import React from "react";
import { Row, Col } from "antd";

import styles from "./HeroSection.module.less";
import { COLOR_GRADIENT } from "shared/constants";

/**
 * @typedef {object} Props
 * @prop {string} title
 * @prop {string} backgroundImage
 * @prop {React.ReactElement} children
 *
 * @param {Props} props
 */
export default function HeroSection(props) {
  const { title, backgroundImage, children } = props;
  return (
    <section
      style={{ backgroundImage: `url(${backgroundImage}), ${COLOR_GRADIENT}` }}
      className={styles.container}
    >
      <Row className={styles.contentRow}>
        {/* Simple way to be responsive */}
        <Col md={24} lg={10}>
          <h1>{title}</h1>
          <div>{children}</div>
        </Col>
      </Row>
    </section>
  );
}
