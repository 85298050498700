import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";

import Layout from "components/Layout";
import HTMLContainer from "components/HTMLContainer";

import HeroSection from "components/HeroSection";
import SearchableList from "components/SearchableList";
import ENThumbnail from "components/ENThumbnail";
import SEO, { createQueryImageFromFile } from "components/SEO";

import styles from "./styles.module.less";

import headerCubesUrl from "assets/backgrounds/header_cubes.svg";

/** TODO: move to page data to align logic */
const DATE_PUBLISHED = new Date("2019-08-12T12:51:31+00:00");

export const pageQuery = graphql`
  query DirectoryIndexPage($id: String!) {
    # Search index expert network
    # TODO: can be static
    localSearchExpertNetwork {
      index
    }
    markdownRemark(id: { eq: $id }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        description
        sharingImage {
          image {
            ...OpenGraphImage
            ...TwitterImage
            ...StructuredDataImage
          }
          alt
          caption
        }
      }
    }
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: {
        frontmatter: {
          template: { eq: "expertNetwork" }
          published: { eq: true }
        }
      }
    ) {
      categories: distinct(field: frontmatter___category)
      # Uncomment when we add support for tag filtering on top
      # tags: distinct(field: frontmatter___tags)
      edges {
        node {
          ...ENThumbnail
        }
      }
    }
  }
`;

/**
 * @typedef {object} Props
 * @prop {Location} location
 * @prop {GatsbyTypes.DirectoryIndexPageQuery} data
 * @param {Props} props
 */
export default function Directory(props) {
  const {
    location: { pathname },
    data: {
      markdownRemark: {
        html,
        excerpt,
        frontmatter: { title: pageTitle, description, sharingImage },
      },
      allMarkdownRemark,
      localSearchExpertNetwork,
    },
  } = props;

  const [
    openGraph,
    twitter,
    sdPrimaryImage,
  ] = createQueryImageFromFile(sharingImage, [
    "openGraphImage.resize",
    "twitterImage.resize",
    "sdImage.resize",
  ]);

  const latestUpdate = allMarkdownRemark.edges.reduce((acc, edge) => {
    return new Date(
      Math.max(
        new Date(edge.node.fields.dateModified).getTime(),
        new Date(acc).getTime()
      )
    );
  }, new Date(allMarkdownRemark.edges[0].node.fields.dateModified));

  return (
    <Layout>
      <SEO
        pathname={pathname}
        title={pageTitle}
        description={description || excerpt}
        images={{ openGraph, twitter, sdPrimaryImage }}
        datePublished={DATE_PUBLISHED}
        dateModified={latestUpdate}
      />
      <HeroSection title={pageTitle} backgroundImage={headerCubesUrl}>
        <HTMLContainer html={html} />
      </HeroSection>
      <div className={classNames(styles.translateTop, styles.globalContainer)}>
        <SearchableList
          index={localSearchExpertNetwork.index}
          pagination={{
            defaultPageSize: 30,
          }}
          searchConfig={{
            fields: {
              title: { boost: 2, bool: "AND" },
              body: {},
              tags: {},
              headquarters: {},
            },
          }}
          categories={{
            search: (selectedCategory, node) =>
              selectedCategory === node.frontmatter.category,
            values: allMarkdownRemark.categories.filter((cat) => !!cat),
          }}
          dataSource={allMarkdownRemark.edges.map((edge) => edge.node)}
          renderItem={(network, index) => (
            <ENThumbnail key={`enthumbnail-${index}`} {...network} />
          )}
        />
      </div>
    </Layout>
  );
}
