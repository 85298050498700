import React from "react";
import queryString from "query-string";

import {
  BookOutlined,
  TagsOutlined,
  LinkOutlined,
  DisconnectOutlined,
} from "@ant-design/icons";

import { Link } from "utils";

import getIcon from "components/Icons";

import styles from "./ENThumbnail.module.less";

/**
 * Can be used to declare structured data fields in the future
 *
 * @typedef {object} FieldProps
 * @prop {string} [className]
 * @prop {React.ReactNode} icon
 * @prop {React.ReactNode} children
 * @prop {boolean} [condition]
 * @param {FieldProps} props
 */
const ENField = ({ icon, children, className, condition = true }) =>
  /** Falsy children -> render nothing */
  children && condition ? (
    <div className={className}>
      {icon} {children}
    </div>
  ) : null;

const getURLHostname = (url) => {
  try {
    const hostname = new URL(url).hostname;
    return hostname;
  } catch (error) {
    // url is empty or invalid
    return null;
  }
};

const getCategoryLink = (category) =>
  "/expert-network-directory?" + queryString.stringify({ category });

/**
 * @typedef {object} Props
 * @prop {string} [className]
 * @prop {string} website
 * @prop {string} headquarters
 * @prop {string} category
 * @prop {readonly string[]} tags
 * @prop {boolean} inactive
 *
 * @param {Props} props
 */
export default function ENFields(props) {
  const { className, website, headquarters, category, tags, inactive } = props;
  const websiteHostname = getURLHostname(website);
  return (
    <div className={className}>
      <ENField icon={getIcon("pin")}>{headquarters}</ENField>
      <ENField
        className={styles.website}
        icon={<LinkOutlined />}
        condition={!!websiteHostname}
      >
        <Link to={website} target="_blank" rel="noopener noreferrer">
          {websiteHostname}
        </Link>
      </ENField>
      <ENField
        condition={!!category}
        className={styles.category}
        icon={<BookOutlined />}
      >
        <Link to={getCategoryLink(category)}>{category}</Link>
      </ENField>
      <ENField icon={<TagsOutlined />}>{tags.join(", ")}</ENField>
      <ENField icon={<DisconnectOutlined />}>
        {inactive ? "Inactive" : null}
      </ENField>
    </div>
  );
}
