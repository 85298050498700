import React from "react";
import { Row, Col } from "antd";
import { graphql } from "gatsby";

import { LinkButton } from "components/Buttons";
import Image from "components/Image";
import ENFields from "./ENFields";

import getIcon from "components/Icons";

import styles from "./ENThumbnail.module.less";

export const ENThumbnailImageFragment = graphql`
  fragment ENThumbnailImage on File {
    childImageSharp {
      fluid(
        # Center crop
        fit: CONTAIN
        quality: 90
        cropFocus: CENTER
        maxWidth: 300
        maxHeight: 300
        # Fill the square with white
        background: "white"
      ) {
        # There's a bug that has cropped base64
        # to always have a black background.
        ...GatsbyImageSharpFluid_noBase64
      }
    }
    ...FileInformation
  }
`;

/**
 * We use this to define what will be queried for this component
 * This also serves as typings (See `Gatsby.ENThumbnailFragment` below)
 */
export const ENThumbnailFragment = graphql`
  fragment ENThumbnail on MarkdownRemark {
    id
    fields {
      path
      dateModified
    }
    excerpt(pruneLength: 200)
    frontmatter {
      title
      category
      headquarters
      description
      inactive
      tags
      website
      logo {
        image {
          ...ENThumbnailImage
        }
        alt
        caption
      }
    }
  }
`;

/** @param {GatsbyTypes.ENThumbnailFragment} props */
export default function ENThumbnail(props) {
  const {
    frontmatter: {
      logo,
      title,
      website,
      description,
      headquarters,
      tags,
      category,
      inactive,
    },
    excerpt,
    fields: { path },
  } = props;
  return (
    <Row
      gutter={{
        lg: 24,
        xl: 48,
      }}
      align="middle"
      justify="center"
      className={styles.container}
    >
      <Col className={styles.image} xs={12} lg={4}>
        {logo.image && (
          <Image
            publicURL={logo.image.publicURL}
            src={logo.image.childImageSharp}
            alt={logo.alt}
          />
        )}
      </Col>
      <Col className={styles.column} xs={24} lg={8}>
        <h2 className={styles.name}>{title}</h2>
        <ENFields
          category={category}
          headquarters={headquarters}
          inactive={inactive}
          tags={tags}
          website={website}
        />
      </Col>
      <Col className={styles.column} xs={24} lg={12}>
        <p>{description || excerpt}</p>
        <LinkButton
          title="Read more"
          icon={getIcon("arrowForward")}
          to={path}
        />
      </Col>
    </Row>
  );
}
